import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

function cleanMessageVCard(message) {
  if (!message) return <br />;

  if (message === "Localização" || message === "Localização em Tempo Real") {
    return (
      <span>
        <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: "5px" }} />
        {message}
      </span>
    );
  }

  if (message === "Imagem") {
    return (
      <span>
        <FontAwesomeIcon icon={faImage} style={{ marginRight: "5px" }} />
        Imagem
      </span>
    );
  }

  if (message.includes("BEGIN:VCARD")) {
    return "Contato";
  }

  return message.length > 40 ? `${message.substring(0, 35)}...` : message;
}

export { cleanMessageVCard };
