import { makeStyles } from "@material-ui/core";

const otherStyle = makeStyles((theme) => ({
  containerDownloadButton: {
    textAlign: "center",
    margin: "6px 0px 5px 0px",
  },

  containerAck: {
    position: 'absolute',
    left: '113px',
    bottom: '0px',
    color: '#999999'
  },
}));

export default otherStyle;
