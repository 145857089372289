import React from "react";
import { Avatar, CardHeader } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import useProfilePicture from "../../hooks/useProfilePicture";

// 1. Memoização do Componente AvatarWithPicture
const AvatarWithPicture = React.memo(({ profilePicUrl, contactName, idGraf }) => {
  const { blobUrl, loading } = useProfilePicture(profilePicUrl, idGraf);

  const getInitial = (name) => (name ? name.charAt(0).toUpperCase() : "");

  return (
    <Avatar
      src={loading ? undefined : blobUrl || "/path/to/default/avatar.png"}
      alt="contact_image"
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = "/path/to/default/avatar.png";
      }}
    >
      {getInitial(contactName)}
    </Avatar>
  );
});

const TicketInfo = ({ contact, ticket, onClick }) => {
  const idGraf = localStorage.getItem("idGraf");

  return (
    <CardHeader
      onClick={onClick}
      style={{ cursor: "pointer" }}
      titleTypographyProps={{ noWrap: true }}
      subheaderTypographyProps={{ noWrap: true }}
      // 2. Substituição do Avatar padrão pelo AvatarWithPicture memoizado
      avatar={
        contact?.profilePicUrl ? (
          <AvatarWithPicture
            profilePicUrl={contact.profilePicUrl}
            contactName={contact.name}
            idGraf={idGraf}
          />
        ) : (
          <Avatar>{contact?.name ? contact.name.charAt(0).toUpperCase() : ""}</Avatar>
        )
      }
      title={`${contact?.name}`}
      subheader={
        ticket.user &&
        `${i18n.t("messagesList.header.assignedTo")} ${
          ticket.user.name.charAt(0).toUpperCase() +
          ticket.user.name.slice(1).toLowerCase()
        }${ticket.queue ? ` - ${ticket.queue.name}` : ""}`
      }
    />
  );
};

export default TicketInfo;
