import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import {
  Badge,
  Box,
  Grid,
  Tooltip,
  IconButton
} from "@material-ui/core";
import PubSub from "pubsub-js";

import TicketService from "../../services/Tickets";
import "./styleListOpen.css";
import { cleanMessageVCard } from "../../utils/cleanMessageVCard";
import connectToSocket from "../../services/socket-io";
import { Skeleton } from "@material-ui/lab";
import useProfilePicture from "../../hooks/useProfilePicture";
import Icon from "../Icon";
import { api } from "../../services/api";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { ExpandMore } from "@material-ui/icons";
import { AuthContext } from "../../context/Auth/AuthContext";

const AvatarWithPicture = React.memo(({ profilePicUrl, contactName, idGraf }) => {
  const { blobUrl, loading } = useProfilePicture(profilePicUrl, idGraf);

  const getInitial = (name) => (name ? name.charAt(0).toUpperCase() : "");

  return (
    <Avatar
      src={loading ? undefined : blobUrl || "/path/to/default/avatar.png"}
      alt={contactName}
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = "/path/to/default/avatar.png";
      }}
    >
      {getInitial(contactName)}
    </Avatar>
  );
});

const TicketListItemOpen = ({
  selectedQueueIds = [],
  selectedTagsIds = [],
  visibility
}) => {
  const history = useHistory();
  const idGraf = localStorage.getItem("idGraf");
  const { ticketId } = useParams();

  const [tickets, setTickets] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [connectionError, setConnectionError] = useState(false);

  const [dropdownClass, setDropdownClass] = useState("");


  const connectionErrorRef = useRef(connectionError);
  const visibilityRef = useRef(visibility);
  const socketRef = useRef(null);

  const [whatsApps, setWhatsApps] = useState([]);
  const hasMultipleWhatsApps = whatsApps.length > 1;

  const pageSize = 15;

  const { user } = useContext(AuthContext);

  const fetchOpenTickets = async () => {
    setIsLoading(true);
    try {
      const data = await TicketService.getTicketsByStatus(
        pageSize,
        pageNumber,
        "open",
        selectedQueueIds,
        selectedTagsIds,
        visibility
      );
      const { tickets: newTickets, hasMore } = data;
      setTickets((prevTickets) => {
        const prevTicketIds = new Set(prevTickets.map((ticket) => ticket.id));
        const uniqueNewTickets = newTickets.filter(
          (tk) => !prevTicketIds.has(tk.id)
        );
        return [...prevTickets, ...uniqueNewTickets];
      });
      setHasMore(hasMore);
    } catch (error) {
      console.error("Erro ao buscar tickets abertos:", error);
    } finally {
      setIsLoading(false);
      setInitialLoading(false);
    }
  };

  useEffect(() => {
    if (hasMore && !isLoading) {
      fetchOpenTickets();
    }
  }, [pageNumber, hasMore]);

  useEffect(() => {
    fetchOpenTickets();
    visibilityRef.current = visibility;
  }, [visibility]);

  useEffect(() => {
    setTickets([]);
    setPageNumber(1);
    setHasMore(true);
  }, [selectedQueueIds, selectedTagsIds, visibility]);

  const loadMore = () => {
    if (hasMore && !isLoading) {
      setPageNumber((prev) => prev + 1);
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight + 50 && hasMore && !isLoading) {
      loadMore();
    }
  };

  const handleSelectTicket = async (ticket) => {
    if (ticket.unreadMessages !== 0 || ticket.unread) {
      try {
        await TicketService.updateTicket(ticket.id, {
          unreadMessages: 0,
          isSelected: true,
          unread: false,
          userId: ticket?.userId,
          status: ticket?.status
        });
        PubSub.publish("TICKET_ACTION_SELECTED", { ticketId: ticket.id });

        setTickets((prevTickets) => {
          const updatedTickets = prevTickets.map((t) =>
            t.id === ticket.id ? { ...t, unreadMessages: 0 } : t
          );
          return updatedTickets;
        });
      } catch (error) {
        console.error("Erro ao atualizar o ticket:", error);
      }
    }
    history.push(`/tickets/${ticket.id}`);
  };

  useEffect(() => {
    const handleTicketUpdates = () => {
      const listener = PubSub.subscribe(
        "TICKET_ACTION_UPDATED",
        async (_, { newStatus, ticketId }) => {
          setTickets((prevTickets) => {
            let updatedTickets = prevTickets;
            if (
              newStatus === "pending" ||
              newStatus === "closed" ||
              newStatus === "deleted"
            ) {
              updatedTickets = updatedTickets.filter(
                (ticket) => ticket.id !== ticketId
              );
            }

            updatedTickets = updatedTickets.filter((ticket) => ticket.userId);
            return updatedTickets;
          });

          if (newStatus === "transferTicket") {
            try {
              const updatedTicket = await TicketService.getTicketById(ticketId);
              setTickets((prevTickets) => {
                const existingTicketIndex = prevTickets.findIndex(
                  (tk) => tk.id === Number(ticketId)
                );
                if (existingTicketIndex !== -1) {
                  const existingTicket = prevTickets[existingTicketIndex];
                  if (existingTicket.lastMessage !== updatedTicket.lastMessage) {
                    let updatedTickets = prevTickets.filter(
                      (tk) => tk.id !== Number(ticketId)
                    );
                    updatedTickets = [updatedTicket, ...updatedTickets];
                    updatedTickets = updatedTickets.filter(
                      (tk) => tk.userId
                    );
                    return updatedTickets;
                  } else {
                    let updatedTickets = [...prevTickets];
                    updatedTickets[existingTicketIndex] = updatedTicket;
                    updatedTickets = updatedTickets.filter(
                      (tk) => tk.userId
                    );
                    return updatedTickets;
                  }
                } else {
                  let updatedTickets = [updatedTicket, ...prevTickets];
                  updatedTickets = updatedTickets.filter((tk) => tk.userId);
                  return updatedTickets;
                }
              });
            } catch (error) {
              console.error("Erro ao buscar o ticket atualizado:", error);
            }
          }
        }
      );
      return () => {
        PubSub.unsubscribe(listener);
      };
    };
    const unsubscribe = handleTicketUpdates();
    return () => {
      unsubscribe();
    };
  }, []);

  const setupSocketListeners = () => {
    if (!socketRef.current) return;

    socketRef.current.on("connect", () => {
      console.log("Socket conectado");
      console.log("connectionErrorRef.current:", connectionErrorRef.current);
      if (connectionErrorRef.current) {
        console.log("Conexão restabelecida, atualizando tickets");
        setConnectionError(false);
        setTickets([]);
        setPageNumber(1);
        setHasMore(true);
        fetchOpenTickets();
      }
    });

    socketRef.current.on("disconnect", (reason) => {
      console.log("Socket desconectado:", reason);
      setConnectionError(true);
      connectionErrorRef.current = true;
      console.log("connectionError após disconnect:", connectionErrorRef.current);
    });

    socketRef.current.on("connect_error", (error) => {
      console.log("Erro de conexão:", error);
      setConnectionError(true);
      connectionErrorRef.current = true;
      console.log("connectionError após connect_error:", connectionErrorRef.current);
    });

    socketRef.current.on(`ticket-open-${idGraf}`, (data) => {
      const updatedTicket = data.ticket;
      if (updatedTicket) {
        const ticketUserId = updatedTicket?.userId;
        const currentVisibility = visibilityRef.current;

        if (currentVisibility === "Me" && ticketUserId !== user?.id) {
          return;
        }

        if (data.action === "update") {
          setTickets((prevTickets) => {
            const existingTicketIndex = prevTickets.findIndex(
              (ticket) => ticket.id === updatedTicket.id
            );
            if (existingTicketIndex !== -1) {
              const existingTicket = prevTickets[existingTicketIndex];
              if (existingTicket.lastMessage !== updatedTicket.lastMessage) {
                const filteredTickets = prevTickets.filter(
                  (ticket) => ticket.id !== updatedTicket.id
                );
                return [updatedTicket, ...filteredTickets];
              } else {
                const updatedTickets = [...prevTickets];
                updatedTickets[existingTicketIndex] = updatedTicket;
                return updatedTickets;
              }
            } else {
              return [updatedTicket, ...prevTickets];
            }
          });
        } else if (data.action === "new") {
          setTickets((prevTickets) => {
            const ticketExists = prevTickets.some(
              (ticket) => ticket.id === updatedTicket.id
            );
            if (!ticketExists) {
              return [updatedTicket, ...prevTickets];
            } else {
              console.warn(
                `Ticket duplicado recebido para ID: ${updatedTicket.id}`
              );
              return prevTickets;
            }
          });
        }
      }
    });
  };

  useEffect(() => {
    socketRef.current = connectToSocket();
    setupSocketListeners();
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    connectionErrorRef.current = connectionError;
  }, [connectionError]);

  const getInitial = (name) => (name ? name.charAt(0).toUpperCase() : "");

  const formatName = (name, isContactName = true) => {
    if (isContactName) {
      if (!name) return "";
      return name.length > 30 ? `${name.substring(0, 30)}...` : name;
    }
    if (!name) return "";
    return name.length > 10 ? `${name.substring(0, 10)}...` : name;
  };

  const fetchWhatsApps = useCallback(async () => {
    try {
      const { data } = await api.get(`/whatsapp`);
      setWhatsApps(data);
    } catch (err) {
      console.error("Erro ao buscar WhatsApps:", err);
    }
  }, []);
  useEffect(() => {
    fetchWhatsApps();
  }, [fetchWhatsApps]);

  const [hoveredTicketId, setHoveredTicketId] = useState(null);
  const handleMouseEnter = (tId) => setHoveredTicketId(tId);
  const handleMouseLeave = () => setHoveredTicketId(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownPos, setDropdownPos] = useState({ top: 0, left: 2 });
  const [dropdownTicket, setDropdownTicket] = useState(null);
  const dropdownRef = useRef(null);
  const containerRef = useRef(null);

  const handleDropdownOpen = (event, tk) => {
    setDropdownTicket(tk);

    const container = containerRef.current;
    const rect = event.currentTarget.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    const SHIFT_LEFT = 105;
    const top = (rect.bottom - containerRect.top) + container.scrollTop;
    const left = (rect.left - containerRect.left) + container.scrollLeft - SHIFT_LEFT;

    setDropdownPos({ top, left });
    setIsDropdownOpen(true);
  };


  const handleRightClick = (e, tk) => {
    e.preventDefault();
    const arrowEl = document.getElementById(`arrow-${tk.id}`);
    if (!arrowEl) return;
    handleDropdownOpen({ currentTarget: arrowEl }, tk);
  };

  useEffect(() => {
    function handleClickOutside(e) {
      if (isDropdownOpen && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsDropdownOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  const handleMarkAsUnread = async (e) => {
    e.stopPropagation();
    setIsDropdownOpen(false);
    if (!dropdownTicket) return;
    try {
      await api.put(`/tickets/${dropdownTicket.id}/markAsUnread`);
    } catch (err) {
      console.error("Erro ao marcar como (não) lido:", err);
    }
  };

  const getDropdownText = () => {
    if (!dropdownTicket) return "Marcar como não lido";
    return dropdownTicket.unread ? "Marcar como lido" : "Marcar como não lido";
  };

  const showBadge = (ticket) => {
    if (ticket.unread) {
      return <Badge badgeContent="" classes={{ badge: "badgeStyleOpen" }} />;
    }
    if (ticket.unreadMessages > 0) {
      return (
        <Badge badgeContent={ticket.unreadMessages} classes={{ badge: "badgeStyleOpen" }} />
      );
    }
    return null;
  };

  useEffect(() => {
    if (isDropdownOpen) {
      requestAnimationFrame(() => {
        setDropdownClass("open");
      });
    } else {
      setDropdownClass("");
    }
  }, [isDropdownOpen]);

  return (
    <div ref={containerRef} onScroll={handleScroll} className="box-tickets" style={{ position: "relative" }}>
      {initialLoading ? (
        <Box style={{ display: "flex", alignItems: "center", padding: "20px" }}>
          <Skeleton
            animation="wave"
            variant="circle"
            width={45}
            height={45}
            style={{ marginRight: "24px" }}
          />
          <div>
            <Skeleton animation="wave" height={15} width={350} />
            <Skeleton animation="wave" height={15} width={350} />
            <Skeleton animation="wave" height={15} width={50} />
          </div>
        </Box>
      ) : tickets.length > 0 ? (
        tickets.map((ticket) => (
          <React.Fragment key={ticket.id}>
            <ListItem
              dense
              onMouseEnter={() => handleMouseEnter(ticket.id)}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleSelectTicket(ticket)}
              onContextMenu={(e) => handleRightClick(e, ticket)}
              selected={ticketId && +ticketId === ticket.id}
              className="hoverPointer"
            >
              <Tooltip
                arrow
                placement="right"
                title={ticket?.queue?.name || "Sem departamento"}
              >
                <span
                  style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }}
                  className="ticketQueueColor"
                ></span>
              </Tooltip>
              <ListItemAvatar>
                {ticket?.contact ? (
                  ticket.contact.profilePicUrl ? (
                    <AvatarWithPicture
                      profilePicUrl={ticket.contact.profilePicUrl}
                      contactName={ticket.contact.name}
                      idGraf={idGraf}
                    />
                  ) : (
                    <Avatar>{getInitial(ticket.contact.name)}</Avatar>
                  )
                ) : (
                  <Skeleton variant="circle" width={45} height={45} />
                )}
              </ListItemAvatar>
              <ListItemText
                disableTypography
                primary={
                  <Grid
                    container
                    spacing={4}
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Grid
                      item
                      xs={9}
                      style={{
                        marginBottom: "-10px",
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "space-between"
                      }}
                    >
                      <Tooltip title={ticket?.contact?.name || ""}>
                        <Typography
                          noWrap
                          component="span"
                          variant="body2"
                          color="textPrimary"
                          style={{ fontWeight: "500" }}
                        >
                          {formatName(ticket?.contact?.name, true)}
                        </Typography>
                      </Tooltip>
                      {hasMultipleWhatsApps && ticket.whatsapp?.name && (
                        <Tooltip title={ticket.whatsapp.name}>
                          <Box
                            display="flex"
                            alignItems="center"
                            style={{
                              boxShadow: "0px 0px 0px rgba(94, 94, 94, 0.1)",
                              borderRadius: "6px",
                              marginRight: "-44px",
                            }}
                          >
                            <Icon type="connectionNumber" />
                            <Typography
                              noWrap
                              component="span"
                              variant="body2"
                              color="textSecondary"
                              style={{
                                fontWeight: "400",
                                fontSize: "11px",
                                borderRadius: "4px",
                                padding: "2px 6px"
                              }}
                            >
                              {formatName(ticket.whatsapp.name, false)}
                            </Typography>
                          </Box>
                        </Tooltip>
                      )}
                    </Grid>

                    <Grid item xs={2} className="box-lastMessage-lastTime">
                      {ticket.lastMessage && ticket.lastMessageTime && (
                        <Typography
                          style={{
                            color: (ticket.unread || ticket.unreadMessages > 0) ? "#4caf50" : "",
                            fontSize: "13px",
                          }}
                          className={
                            isSameDay(parseISO(ticket.lastMessageTime), new Date())
                              ? "lastMessageTime"
                              : "lastMessageDate"
                          }
                          component="span"
                          variant="body2"
                          color="textSecondary"
                        >
                          {isSameDay(parseISO(ticket.lastMessageTime), new Date())
                            ? format(parseISO(ticket.lastMessageTime), "HH:mm")
                            : format(parseISO(ticket.lastMessageTime), "dd/MM/yyyy")}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                }
                secondary={
                  <Grid
                    container
                    spacing={4}
                    alignItems="center"
                    justifyContent="flex-start"
                    className={ticket.status === "closed" ? "gridWithCustomSpacing" : ""}
                  >
                    <Grid
                      item
                      xs={9}
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "space-between"
                      }}
                    >
                      <Typography
                        className={
                          ticket.status === "closed"
                            ? "contactLastMessageClosed"
                            : "contactLastMessage"
                        }
                        noWrap
                        component="span"
                        variant="body2"
                        color="textSecondary"
                      >
                        {cleanMessageVCard(ticket.lastMessage) || <br />}
                      </Typography>
                    </Grid>

                    {/* Mantemos o botão sempre no DOM, mas ocultamos se não estiver em hover */}
                    <IconButton
                      id={`arrow-${ticket.id}`}
                      size="small"
                      style={{
                        position: 'absolute',
                        left: '420px',
                        visibility: hoveredTicketId === ticket.id ? "visible" : "hidden"
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDropdownOpen(e, ticket);
                      }}

                    >
                      <ExpandMore />
                    </IconButton>
                    <Grid item xs={1}>
                      {showBadge(ticket)}
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))
      ) : (
        <div className="no-tickets-div">
          <span className="no-tickets-title">Não há nada aqui!</span>
          <p className="no-tickets-text">Não há nenhum ticket em atendimento</p>
        </div>
      )}

      {/* Dropdown custom */}
      {isDropdownOpen && (
        <div
          ref={dropdownRef}
          className={`customDropdown ${dropdownClass}`}
          style={{
            position: "absolute",
            top: dropdownPos.top,
            left: dropdownPos.left,
            backgroundColor: "#fff",
            boxShadow: "0px 2px 10px rgba(0,0,0,0.2)",
            borderRadius: 4,
            zIndex: 9999,
            padding: "5px 0"
          }}
        >
          <div
            className="dropdownItem"
            style={{
              padding: "6px 16px",
              fontSize: "14px",
              cursor: "pointer",
              whiteSpace: "nowrap",
              minWidth: "171px"
            }}
            onClick={handleMarkAsUnread}
          >
            {getDropdownText()}
          </div>
        </div>
      )}
    </div>
  );
};

export default TicketListItemOpen;
