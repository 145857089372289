import React, {
  useState,
  useEffect,
  useRef,
  useCallback
} from "react";
import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import {
  Badge,
  Box,
  Grid,
  Tooltip,
  IconButton
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import PubSub from "pubsub-js";

import { api } from "../../services/api";
import TicketService from "../../services/Tickets";
import TransferTicketModal from "../TransferTicketModal";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { i18n } from "../../translate/i18n";
import connectToSocket from "../../services/socket-io";
import useProfilePicture from "../../hooks/useProfilePicture";
import Icon from "../Icon";

import { cleanMessageVCard } from "../../utils/cleanMessageVCard";
import "./styleListPending.css";
import { ExpandMore } from "@material-ui/icons";

const AvatarWithPicture = React.memo(({ profilePicUrl, contactName, idGraf }) => {
  const { blobUrl, loading } = useProfilePicture(profilePicUrl, idGraf);

  const getInitial = (name) => (name ? name.charAt(0).toUpperCase() : "");
  return (
    <Avatar
      src={loading ? undefined : blobUrl || "/path/to/default/avatar.png"}
      alt={contactName}
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = "/path/to/default/avatar.png";
      }}
    >
      {getInitial(contactName)}
    </Avatar>
  );
});

const TicketListItemPending = ({
  selectedQueueIds = [],
  selectedTagsIds = [],
  visibility
}) => {
  const history = useHistory();
  const { ticketId } = useParams();
  const idGraf = localStorage.getItem("idGraf");

  const [tickets, setTickets] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  const [ticket, setTicket] = useState(null);
  const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);

  const [hoveredTicketId, setHoveredTicketId] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownPos, setDropdownPos] = useState({ top: 0, left: 0 });
  const [dropdownTicket, setDropdownTicket] = useState(null);

  const visibilityRef = useRef(visibility);
  const socketRef = useRef(null);
  const containerRef = useRef(null);
  const dropdownRef = useRef(null);

  const [whatsApps, setWhatsApps] = useState([]);
  const [dropdownClass, setDropdownClass] = useState("");

  const hasMultipleWhatsApps = whatsApps.length > 1;
  const pageSize = 15;

  const fetchPendingTickets = async () => {
    setIsLoading(true);
    try {
      const data = await TicketService.getTicketsByStatus(
        pageSize,
        pageNumber,
        "pending",
        selectedQueueIds,
        selectedTagsIds,
        visibility
      );
      const { tickets: newTickets, hasMore } = data;

      setTickets((prevTickets) => {
        const prevIds = new Set(prevTickets.map((t) => t.id));
        const uniqueNew = newTickets.filter((t) => !prevIds.has(t.id));
        return [...prevTickets, ...uniqueNew];
      });
      setHasMore(hasMore);
    } catch (error) {
      console.error("Erro ao buscar tickets pendentes:", error);
    } finally {
      setIsLoading(false);
      setInitialLoading(false);
    }
  };

  useEffect(() => {
    if (hasMore && !isLoading) {
      fetchPendingTickets();
    }
  }, [pageNumber, hasMore]);

  useEffect(() => {
    visibilityRef.current = visibility;
  }, [visibility]);

  useEffect(() => {
    setTickets([]);
    setPageNumber(1);
    setHasMore(true);
  }, [selectedQueueIds, selectedTagsIds, visibility]);

  useEffect(() => {
    socketRef.current = connectToSocket();
    const socket = socketRef.current;

    socket.on(`ticket-${idGraf}`, (data) => {
      if (data.action === "newTicketIncoming") {
        const newTicket = data.ticket;
        if (newTicket) {
          setTickets((prevTickets) => [newTicket, ...prevTickets]);
          PubSub.publish("TICKET_ACTION_UPDATED", {
            newStatus: "newTicketIncoming",
            ticket: newTicket
          });
        }
      }
    });

    socket.on(`ticket-pending-${idGraf}`, (data) => {
      if (data.action === "update") {
        const updatedTicket = data.ticket;
        if (updatedTicket) {
          setTickets((prevTickets) => {
            const idx = prevTickets.findIndex((t) => t.id === updatedTicket.id);
            if (idx !== -1) {
              const clone = [...prevTickets];
              clone[idx] = updatedTicket;
              return clone;
            }
            return [updatedTicket, ...prevTickets];
          });
        }
      }
    });

    socket.on(`ticket-updated-${idGraf}`, (data) => {
      if (data.action === "transferTicket") {
        setTickets((prevTickets) =>
          prevTickets.filter((t) => t.id !== data.ticketId)
        );
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [idGraf]);

  useEffect(() => {
    const listener = PubSub.subscribe(
      "TICKET_ACTION_UPDATED",
      async (_, { newStatus, ticketId }) => {
        setTickets((prevTickets) => {
          if (newStatus === "transferTicket") {
            return prevTickets.filter((t) => t.id !== ticketId);
          }
          return prevTickets;
        });
      }
    );
    return () => {
      PubSub.unsubscribe(listener);
    };
  }, []);

  const loadMore = () => {
    if (hasMore && !isLoading) {
      setPageNumber((prev) => prev + 1);
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight + 50 && hasMore && !isLoading) {
      loadMore();
    }
  };

  const handleSelectTicket = async (tk) => {
    if (tk.unreadMessages !== 0 || tk.unread) {
      try {
        await api.put(`/tickets/${tk.id}/markAsRead`, {
          unreadMessages: 0,
          isSelected: true,
          unread: false
        });
        PubSub.publish("TICKET_ACTION_SELECTED", { ticketId: tk.id });
        setTickets((prevTickets) => {
          return prevTickets.map((t) =>
            t.id === tk.id ? { ...t, unreadMessages: 0 } : t
          );
        });
      } catch (error) {
        console.error("Erro ao atualizar o ticket:", error);
      }
    }
    history.push(`/tickets/${tk.id}`);
  };

  const handleOpenTransferModal = (tk) => {
    setTicket(tk);
    setTransferTicketModalOpen(true);
  };
  const handleCloseTransferTicketModal = () => {
    setTransferTicketModalOpen(false);
  };

  const getInitial = (name) => (name ? name.charAt(0).toUpperCase() : "");
  const formatName = (name, isContactName = true) => {
    if (!name) return "";
    if (isContactName) {
      return name.length > 20 ? `${name.substring(0, 40)}...` : name;
    }
    return name.length > 20 ? `${name.substring(0, 20)}...` : name;
  };

  const fetchWhatsApps = useCallback(async () => {
    try {
      const { data } = await api.get(`/whatsapp`);
      setWhatsApps(data);
    } catch (err) {
      console.error("Erro ao buscar WhatsApps:", err);
    }
  }, []);
  useEffect(() => {
    fetchWhatsApps();
  }, [fetchWhatsApps]);

  const handleMouseEnter = (tid) => setHoveredTicketId(tid);
  const handleMouseLeave = () => setHoveredTicketId(null);


  const handleRightClick = (e, tk) => {
    e.preventDefault();
    e.stopPropagation();
    const arrowEl = document.getElementById(`arrow-${tk.id}`);
    if (!arrowEl) return;
    handleDropdownOpen({ currentTarget: arrowEl }, tk);
  };


  const handleDropdownOpen = (event, tk) => {
    setDropdownTicket(tk);

    const container = containerRef.current;
    const rect = event.currentTarget.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();


    const SHIFT_LEFT = 105;
    const top = (rect.bottom - containerRect.top) + container.scrollTop;
    const left = (rect.left - containerRect.left) + container.scrollLeft - SHIFT_LEFT;

    setDropdownPos({ top, left });
    setIsDropdownOpen(true);
  };

  useEffect(() => {
    function handleClickOutside(ev) {
      if (
        isDropdownOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(ev.target)
      ) {
        setIsDropdownOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);


  const handleMarkAsUnread = async (e) => {
    e.stopPropagation();
    setIsDropdownOpen(false);

    if (!dropdownTicket) return;
    try {
      await api.put(`/tickets/${dropdownTicket.id}/markAsUnread`);
    } catch (error) {
      console.error("Erro ao alternar unread:", error);
    }
  };

  const getTicketItemClass = (tk) => {
    if (tk.unread === true || tk.unreadMessages > 0) {
      return "pendingTicket hasBadge";
    }
    return "pendingTicket noBadge";
  };

  const getDropdownText = () => {
    if (!dropdownTicket) return "Marcar como não lido";
    return dropdownTicket.unread
      ? "Marcar como lido"
      : "Marcar como não lido";
  };

  const showDotBadge = (tk) =>
    tk.unread ? (
      <Badge badgeContent="" classes={{ badge: "badgeStylePending" }} />
    ) : tk.unreadMessages > 0 ? (
      <Badge badgeContent={tk.unreadMessages} classes={{ badge: "badgeStylePending" }} />
    ) : null;


  useEffect(() => {
    if (isDropdownOpen) {
      requestAnimationFrame(() => {
        setDropdownClass("open");
      });
    } else {
      setDropdownClass("");
    }
  }, [isDropdownOpen]);


  return (
    <div
      ref={containerRef}
      onScroll={handleScroll}
      className="box-tickets"
      style={{ position: "relative" }}
    >
      {initialLoading ? (
        <Box style={{ display: "flex", alignItems: "center", padding: "20px" }}>
          <Skeleton
            animation="wave"
            variant="circle"
            width={45}
            height={45}
            style={{ marginRight: "24px" }}
          />
          <div>
            <Skeleton animation="wave" height={15} width={350} />
            <Skeleton animation="wave" height={15} width={350} />
            <Skeleton animation="wave" height={15} width={50} />
          </div>
        </Box>
      ) : tickets.length > 0 ? (
        tickets.map((tk) => (
          <React.Fragment key={tk.id}>
            <ListItem
              dense
              className={`hoverPointer ${getTicketItemClass(tk)}`}
              style={{ cursor: "pointer" }}
              onMouseEnter={() => handleMouseEnter(tk.id)}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleSelectTicket(tk)}
              onContextMenu={(e) => handleRightClick(e, tk)}
              selected={ticketId && +ticketId === tk.id}
            >
              <Tooltip
                arrow
                placement="right"
                title={tk.queue?.name || "Sem departamento"}
              >
                <span
                  style={{
                    backgroundColor: tk.queue?.color || "#7C7C7C"
                  }}
                  className="ticketQueueColor"
                ></span>
              </Tooltip>

              <ListItemAvatar>
                {tk.contact ? (
                  tk.contact.profilePicUrl ? (
                    <AvatarWithPicture
                      profilePicUrl={tk.contact.profilePicUrl}
                      contactName={tk.contact.name}
                      idGraf={idGraf}
                    />
                  ) : (
                    <Avatar>{getInitial(tk.contact.name)}</Avatar>
                  )
                ) : (
                  <Skeleton variant="circle" width={45} height={45} />
                )}
              </ListItemAvatar>

              <ListItemText
                disableTypography
                primary={
                  <Grid
                    container
                    spacing={4}
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Grid
                      item
                      xs={9}
                      style={{
                        marginBottom: "-10px",
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "space-between"
                      }}
                    >
                      <Tooltip title={tk.contact?.name || ""}>
                        <Typography
                          noWrap
                          component="span"
                          variant="body2"
                          color="textPrimary"
                          style={{ fontWeight: "500" }}
                        >
                          {formatName(tk.contact?.name)}
                        </Typography>
                      </Tooltip>
                      {hasMultipleWhatsApps && tk.whatsapp?.name && (
                        <Tooltip title={tk.whatsapp.name}>
                          <Box
                            display="flex"
                            alignItems="center"
                            style={{
                              boxShadow: "0px 0px 0px rgba(94, 94, 94, 0.1)",
                              borderRadius: "6px",
                              marginRight: "-44px"
                            }}
                          >
                            <Icon type="connectionNumber" />
                            <Typography
                              noWrap
                              component="span"
                              variant="body2"
                              color="textSecondary"
                              style={{
                                fontWeight: "400",
                                fontSize: "11px",
                                borderRadius: "4px",
                                padding: "2px 6px"
                              }}
                            >
                              {formatName(tk.whatsapp.name, false)}
                            </Typography>
                          </Box>
                        </Tooltip>
                      )}
                    </Grid>

                    <Grid
                      item
                      xs={2}
                      className="box-lastMessage-lastTime"
                      style={{ marginLeft: "3px" }}
                    >
                      {tk.lastMessage && tk.lastMessageTime && (
                        <Typography
                          style={{
                            fontSize: "13px",
                            marginLeft: "2px",
                            color:
                              tk.unread || tk.unreadMessages > 0
                                ? "#4caf50"
                                : ""
                          }}
                          className={
                            isSameDay(parseISO(tk.lastMessageTime), new Date())
                              ? "lastMessageTime"
                              : "lastMessageDate"
                          }
                          component="span"
                          variant="body2"
                          color="textSecondary"
                        >
                          {isSameDay(parseISO(tk.lastMessageTime), new Date())
                            ? format(parseISO(tk.lastMessageTime), "HH:mm")
                            : format(parseISO(tk.lastMessageTime), "dd/MM/yyyy")}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                }
                secondary={
                  <Grid
                    container
                    spacing={5}
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "space-between"
                      }}
                    >
                      <Typography
                        className="contactLastMessage"
                        noWrap
                        component="span"
                        variant="body2"
                        color="textSecondary"
                      >
                        {cleanMessageVCard(tk.lastMessage) || <br />}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={2}
                      className="box-button-accept"
                      style={{
                        marginLeft: "28px",
                        marginBottom: "3px"
                      }}
                    >
                      <Box display="flex">
                        {hoveredTicketId === tk.id && (
                          <ButtonWithSpinner
                            color="primary"
                            variant="contained"
                            className="reopenButton"
                            size="small"
                            loading={false}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOpenTransferModal(tk);
                            }}
                            disableElevation
                          >
                            {i18n.t("ticketsList.buttons.accept")}
                          </ButtonWithSpinner>
                        )}
                      </Box>
                      <Box>{showDotBadge(tk)}</Box>
                    </Grid>

                    {/* Ícone sempre no DOM, mas com visibility controlada */}
                    <IconButton
                      id={`arrow-${tk.id}`}
                      size="small"

                      onClick={(e) => {
                        e.stopPropagation();
                        handleDropdownOpen(e, tk);
                      }}
                      style={{
                        marginLeft: "148px",
                        marginTop: "2px",
                        visibility:
                          hoveredTicketId === tk.id ? "visible" : "hidden"
                      }}
                    >
                      <ExpandMore />
                    </IconButton>
                  </Grid>
                }
              />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))
      ) : (
        <div className="no-tickets-div">
          <span className="no-tickets-title">Não há nada aqui</span>
          <p className="no-tickets-text">
            Você ainda não recebeu nenhum ticket
          </p>
        </div>
      )}

      {isDropdownOpen && (
        <div
          ref={dropdownRef}
          className={`customDropdownPending ${dropdownClass}`}
          style={{
            position: "absolute",
            top: dropdownPos.top,
            left: dropdownPos.left,
            backgroundColor: "#fff",
            boxShadow: "0px 2px 10px rgba(0,0,0,0.2)",
            borderRadius: 4,
            zIndex: 9999,
            padding: "2px 0"
          }}
        >
          <div
            className="dropdownItem"
            style={{
              padding: "6px 16px",
              fontSize: "14px",
              cursor: "pointer",
              whiteSpace: "nowrap",
              minWidth: "171px"
            }}
            onClick={handleMarkAsUnread}
          >
            {getDropdownText()}
          </div>
        </div>
      )}

      {transferTicketModalOpen && ticket && (
        <TransferTicketModal
          modalOpen={transferTicketModalOpen}
          onClose={handleCloseTransferTicketModal}
          ticketid={ticket.id}
          ticketWhatsappId={ticket.whatsappId}
          ticketQueueId={ticket.queueId}
          ticketStatus={ticket.status}
        />
      )}
    </div>
  );
};

export default TicketListItemPending;
