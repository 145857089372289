import React from "react"
import useS3 from "../../hooks/useS3";

import messageListStyles from "../MessagesList/css/messageListStyles";
import { format, parseISO } from "date-fns";
import { renderMessageAck } from "../../utils/renderMessagesAck";

const AudioCors = ({ audioUrl, message }) => {
  const { blobUrl } = useS3(audioUrl)
  const classes = messageListStyles();

  return (
    blobUrl ? (
      <div >
        <audio controls style={{ maxWidth: '256px' }}>
          <source src={blobUrl} type="audio/ogg" />
        </audio>
        <div style={{ height: '0px', display: 'flex', justifyContent: 'flex-end', color: '#999999' }}>
          <span
            style={{
              position: 'absolute',
              right: message.fromMe ? '26.5px' : '10px',
              bottom: message?.body ? '3px' : '17px',
              fontSize: '11px',
            }}
          >
            {format(parseISO(message.createdAt), "HH:mm")}
          </span>
          <span style={{ marginTop: message.quotedMsg ? "82px" : "" }}>
            {message?.fromMe && renderMessageAck(message, classes)}
          </span>

        </div>

      </div>
    ) : null
  );
};

export default AudioCors;
