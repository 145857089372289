import React from "react";
import { AccessTime, Done, DoneAll } from "@material-ui/icons";

export const renderMessageAck = (message, classes) => {
    console.log(message.ack)
    if (message.ack === 0) {
        return <AccessTime fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 1) {
        return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 2) {
        return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 3) {
        return <DoneAll fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 4 || message.ack === 5) {
        return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
    }
    return null;
};
