import React from "react";
import { GetApp } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import otherStyle from "./css/otherStyle";
import useS3 from "../../hooks/useS3";
import messageListStyles from "../MessagesList/css/messageListStyles";
import { renderMessageAck } from "../../utils/renderMessagesAck";
import { format, parseISO } from "date-fns";

const OtherCors = ({ otherUrl, message }) => {
  const classes = otherStyle();
  const messageStyles = messageListStyles();
  const { blobUrl } = useS3(otherUrl)

  return (
    blobUrl ? (

      <>

        <div style={{ overflow: "hidden" }}>
          <div style={{ marginRight: "-17px" }}></div>
          <div className={classes.containerDownloadButton}>
            <Button
              startIcon={<GetApp />}
              color="primary"
              variant="outlined"
              target="_blank"
              border="none"
              href={blobUrl}
            >
              Download
            </Button>
          </div>

        </div>
        <div className={classes.renderFileAck}>

          <div
            style={{
              position: 'absolute',
              right: message.fromMe ? '26.5px' : '10px',
              bottom: message?.body ? '2px' : '17px',
              fontSize: '11px',
              color: '#AAA59A'
            }}
          >
            {format(parseISO(message.createdAt), "HH:mm")}
            <span style={{
              position: 'absolute',
            }}>
              {message?.fromMe && renderMessageAck(message, messageStyles)}
            </span>
          </div>
        </div>
      </>
    ) : null
  );
};

export default OtherCors;
