

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useS3 from "../../hooks/useS3";

import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { api } from "../../services/api";

const useStyles = makeStyles((theme) => ({
    messageMediaContainer: {
        position: 'relative',
        display: 'inline-block',
        borderRadius: 8,
        overflow: 'hidden',
    },
    messageMedia: {
        objectFit: "cover",
        width: 250,
        height: 200,
        borderRadius: 8,
        cursor: 'pointer',
        transition: 'transform 0.2s',
        display: 'block',
        '&:hover': {
            transform: 'scale(1.02)',
        },
    },
    downloadButton: {
        position: "absolute",
        bottom: 10,
        right: 10,
        backgroundColor: "#D9F1FF",
        color: "#007bff",
        border: "none",
        borderRadius: "50%",
        width: 40,
        height: 40,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
        cursor: "pointer",
        zIndex: 10,
    },
    loadingContainer: {
        width: 250,
        height: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 8,
        backgroundColor: '#f0f0f0',
    },
    errorContainer: {
        width: 250,
        height: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 8,
        backgroundColor: '#ffe6e6',
        color: '#ff0000',
    },
}));

const MessageImage = ({ imageUrl, openModal }) => {
    const classes = useStyles();
    const { blobUrl, loading, error } = useS3(imageUrl);

    const handleDownload = async (e) => {

        try {
            const response = await api.post(`/messages/download-temp`, { imageUrl }, {
                responseType: "blob",
            });

            const disposition = response.headers["content-disposition"];
            const fileName = disposition
                ? disposition.split("filename=")[1].replace(/"/g, "")
                : imageUrl.split("/").pop();

            const url = window.URL.createObjectURL(response.data);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Erro ao baixar o arquivo:", error);
        }
    };

    if (loading) {
        return (
            <div className={classes.loadingContainer}>
                <span>Carregando...</span>
            </div>
        );
    }

    if (error) {
        return (
            <div className={classes.errorContainer}>
                <span>Erro ao carregar imagem.</span>
            </div>
        );
    }

    return (
        <div className={classes.messageMediaContainer}>
            <img
                src={blobUrl}
                alt="message media"
                className={classes.messageMedia}
                onClick={() => openModal(imageUrl)}
            />

            <button
                className={classes.downloadButton}
                onClick={() => handleDownload(imageUrl)}
            >
                <FontAwesomeIcon icon={faDownload} size="lg" />
            </button>

        </div>
    );
};

export default MessageImage;
