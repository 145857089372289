import { useState, useEffect } from "react";
import { api } from "../../services/api";

const useProfilePicture = (mediaURL, idGraf) => {
    const [blobUrl, setBlobUrl] = useState("");
    const [loading, setLoading] = useState(true);

    // 2. Correção das Dependências no Hook useProfilePicture
    useEffect(() => {
        const fetchMedia = async () => {
            if (!mediaURL || !idGraf) {
                console.error("Parâmetros inválidos:", { mediaURL, idGraf });
                setLoading(false);
                return;
            }

            try {
                const { data } = await api.get(`/messages/download-profilePicture/${idGraf}/${mediaURL}`);
                setBlobUrl(data);
            } catch (error) {
                console.error("Erro ao buscar imagem de perfil:", error.response || error);
            } finally {
                setLoading(false);
            }
        };

        fetchMedia();
    }, [mediaURL, idGraf]); // Adicionadas as dependências

    return { blobUrl, loading };
};

export default useProfilePicture;
