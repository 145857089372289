import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import { Box, Grid, Tooltip } from "@material-ui/core";
import PubSub from 'pubsub-js';
import "./style.css";
import { cleanMessageVCard } from "../../utils/cleanMessageVCard";

import TicketService from "../../services/Tickets";
import ButtonWithSpinner from "../ButtonWithSpinner";
import Icon from "../Icon";
import { i18n } from "../../translate/i18n";
import TransferTicketModal from "../TransferTicketModal";
import { Skeleton } from "@material-ui/lab";
import useProfilePicture from "../../hooks/useProfilePicture";
import connectToSocket from "../../services/socket-io"; // 1. Adicionada a importação correta

// 2. Memoização do Componente AvatarWithPicture
const AvatarWithPicture = React.memo(({ profilePicUrl, contactName, idGraf }) => {
    const { blobUrl, loading } = useProfilePicture(profilePicUrl, idGraf);

    const getInitial = (name) => (name ? name.charAt(0).toUpperCase() : "");

    return (
        <Avatar
            src={loading ? undefined : blobUrl || "/path/to/default/avatar.png"}
            alt={contactName}
            onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/path/to/default/avatar.png";
            }}
        >
            {getInitial(contactName)}
        </Avatar>
    );
});

const TicketListItemClosed = ({ selectedQueueIds = [], selectedTagsIds = [], visibility }) => {
    const history = useHistory();
    const { ticketId } = useParams();
    const [tickets, setTickets] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [ticket, setTicket] = useState();
    const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const pageSize = 15;

    // Obter idGraf do localStorage
    const idGraf = localStorage.getItem("idGraf");

    // Referências para evitar re-renderizações desnecessárias
    const connectionErrorRef = useRef(false);
    const visibilityRef = useRef(visibility);
    const socketRef = useRef(null);

    // 3. Função para buscar tickets fechados
    const fetchClosedTickets = async () => {
        setIsLoading(true);
        try {
            const data = await TicketService.getTicketsByStatus(
                pageSize,
                pageNumber,
                "closed",
                selectedQueueIds,
                selectedTagsIds,
                visibility
            );
            const { tickets: newTickets, hasMore } = data;

            setTickets((prevTickets) => {
                const prevTicketIds = new Set(prevTickets.map(ticket => ticket.id));
                const uniqueNewTickets = newTickets.filter(ticket => !prevTicketIds.has(ticket.id));
                return [...prevTickets, ...uniqueNewTickets];
            });

            setHasMore(hasMore);
        } catch (error) {
            console.error("Erro ao buscar tickets fechados:", error); // 4. Mensagem de erro corrigida
        } finally {
            setIsLoading(false);
            setInitialLoading(false);
        }
    };

    // 5. Ajuste nas Dependências do useEffect Principal
    useEffect(() => {
        if (hasMore && !isLoading) {
            fetchClosedTickets();
        }
        // Removido 'tickets' das dependências para evitar re-fetching desnecessário
    }, [pageNumber, hasMore]);

    // Atualizar visibilityRef quando visibility mudar
    useEffect(() => {
        visibilityRef.current = visibility;
    }, [visibility]);

    // Resetar tickets quando filtros mudarem

    useEffect(() => {
        setTickets([]);
        setPageNumber(1);
        setHasMore(true);
    }, [selectedQueueIds, selectedTagsIds, visibility]);

    // 6. Configuração dos Listeners de Socket
    useEffect(() => {
        socketRef.current = connectToSocket();

        const socket = socketRef.current;

        socket.on(`ticket-${idGraf}`, (data) => {
            if (data.action === "newTicketIncoming") {
                const newTicket = data.ticket;
                if (newTicket) {
                    setTickets((prevTickets) => [newTicket, ...prevTickets]);
                    PubSub.publish("TICKET_ACTION_UPDATED", {
                        newStatus: "newTicketIncoming",
                        ticket: newTicket
                    });
                }
            }
        });

        socket.on(`ticket-closed-${idGraf}`, (data) => {
            if (data.action === "update") {
                const updatedTicket = data.ticket;
                if (updatedTicket) {
                    setTickets((prevTickets) => {
                        const ticketIndex = prevTickets.findIndex(ticket => ticket.id === updatedTicket.id);
                        if (ticketIndex !== -1) {
                            const newTickets = [...prevTickets];
                            newTickets[ticketIndex] = updatedTicket;
                            return newTickets;
                        } else {
                            return [updatedTicket, ...prevTickets];
                        }
                    });
                }
            }
        });

        socket.on(`ticket-updated-${idGraf}`, (data) => {
            if (data.action === "transferTicket") {
                setTickets((prevTickets) =>
                    prevTickets.filter((ticket) => ticket.id !== data.ticketId)
                );
            }
        });

        return () => {
            socket.disconnect();
        };
    }, [idGraf]);

    // 7. Listener para atualizações de tickets via PubSub
    useEffect(() => {
        const handleTicketUpdates = () => {
            const listener = PubSub.subscribe("TICKET_ACTION_UPDATED", async (_, { newStatus, ticketId }) => {
                setTickets((prevTickets) => {
                    if (newStatus === "transferTicket") {
                        return prevTickets.filter((ticket) => ticket.id !== ticketId);
                    }
                    return prevTickets;
                });
            });

            return () => {
                PubSub.unsubscribe(listener);
            };
        };

        const unsubscribe = handleTicketUpdates();
        return () => {
            unsubscribe();
        };
    }, []);

    // Função para carregar mais tickets
    const loadMore = () => {
        if (hasMore && !isLoading) {
            setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
    };

    // Função para lidar com o scroll e carregar mais tickets
    const handleScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - scrollTop <= clientHeight + 50 && hasMore && !isLoading) {
            loadMore();
        }
    };

    // Função para selecionar um ticket
    const handleSelectTicket = async (ticket) => {
        history.push(`/tickets/${ticket.id}`);
    };

    // Função para abrir o modal de transferência de ticket
    const handleOpenTransferModal = async (ticket) => {
        setTicket(ticket);
        setTransferTicketModalOpen(true);
    };

    // Função para fechar o modal de transferência de ticket
    const handleCloseTransferTicketModal = () => {
        setTransferTicketModalOpen(false);
    };

    // Função para obter a inicial do nome
    const getInitial = (name) => {
        return name ? name.charAt(0).toUpperCase() : "";
    };

    const formatName = (name) => {
        if (!name) return '';
        return name.length > 40 ? `${name.substring(0, 40)}...` : name;
    };



    return (
        <div onScroll={handleScroll} className="box-tickets">
            {initialLoading ? (
                <Box style={{ display: 'flex', alignItems: 'center', padding: '20px' }}>
                    <Skeleton
                        animation="wave"
                        variant="circle"
                        width={45}
                        height={45}
                        style={{ marginRight: '24px' }}
                    />
                    <div>
                        <Skeleton animation="wave" height={15} width={350} />
                        <Skeleton animation="wave" height={15} width={350} />
                        <Skeleton animation="wave" height={15} width={50} />
                    </div>
                </Box>
            ) : tickets.length > 0 ? (
                tickets.map((ticket) => (
                    <React.Fragment key={ticket.id}>
                        <ListItem
                            dense
                            button
                            onClick={() => handleSelectTicket(ticket)}
                            selected={ticketId && +ticketId === ticket.id}
                            className={clsx("ticket", {
                                "pendingTicket": ticket.status === "pending",
                            })}
                        >
                            <Tooltip
                                arrow
                                placement="right"
                                title={ticket.queue?.name || "Sem departamento"}
                            >
                                <span
                                    style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }}
                                    className="ticketQueueColor"
                                ></span>
                            </Tooltip>
                            <ListItemAvatar>
                                {ticket?.contact?.profilePicUrl ? (
                                    <AvatarWithPicture
                                        profilePicUrl={ticket.contact.profilePicUrl}
                                        contactName={ticket.contact.name}
                                        idGraf={idGraf}
                                    />
                                ) : (
                                    <Avatar>
                                        {getInitial(ticket.contact.name)}
                                    </Avatar>
                                )}
                            </ListItemAvatar>
                            <ListItemText
                                disableTypography
                                primary={
                                    <Grid
                                        container
                                        spacing={4} // Preservado o spacing original
                                        alignItems="center"
                                        justifyContent="flex-start"
                                    >
                                        <Grid item xs={9} style={{ marginBottom: '-10px' }}>
                                            <Tooltip title={ticket?.contact?.name || ""}>
                                                <Typography
                                                    noWrap
                                                    component="span"
                                                    variant="body2"
                                                    color="textPrimary"
                                                    style={{ fontWeight: "500" }}
                                                >
                                                    {formatName(ticket?.contact?.name)}
                                                </Typography>
                                            </Tooltip>

                                        </Grid>
                                        <Grid item xs={2}>
                                            {ticket.lastMessage && ticket.lastMessageTime && (
                                                <Typography
                                                    className={
                                                        isSameDay(parseISO(ticket.lastMessageTime), new Date())
                                                            ? "lastMessageTime"
                                                            : "lastMessageDate"
                                                    }
                                                    style={{ fontSize: "13px" }}
                                                    component="span"
                                                    variant="body2"
                                                    color="textSecondary"
                                                >
                                                    {isSameDay(parseISO(ticket?.lastMessageTime), new Date()) ? (
                                                        format(parseISO(ticket?.lastMessageTime), "HH:mm")
                                                    ) : (
                                                        format(parseISO(ticket?.lastMessageTime), "dd/MM/yyyy")
                                                    )}
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                }
                                secondary={
                                    <Grid
                                        container
                                        spacing={4} // Preservado o spacing original
                                        alignItems="center"
                                        justifyContent="flex-start"
                                        className="gridWithCustomSpacing"
                                    >
                                        <Grid item xs={9}>
                                            <Typography
                                                className="contactLastMessageClosed"
                                                noWrap
                                                component="span"
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                {cleanMessageVCard(ticket.lastMessage) || <br />}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Box display="flex" alignItems="center">
                                                <ButtonWithSpinner
                                                    color="primary"
                                                    variant="contained"
                                                    className="reopenButton"
                                                    size="small"
                                                    loading={false}
                                                    onClick={(e) => {
                                                        e.stopPropagation(); // Prevenir a seleção do ticket ao clicar no botão
                                                        handleOpenTransferModal(ticket);
                                                    }}
                                                    disableElevation
                                                >
                                                    {i18n.t("ticketsList.buttons.accept")}
                                                </ButtonWithSpinner>
                                                <span className="closedBadge">
                                                    <Icon type="solvedTicket" />
                                                </span>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                }
                            />
                        </ListItem>
                        <Divider />
                    </React.Fragment>
                ))
            ) : (
                <div className="no-tickets-div">
                    <span className="no-tickets-title">
                        Não há nada aqui!
                    </span>
                    <p className="no-tickets-text">
                        Não há nenhum ticket em atendimento
                    </p>
                </div>
            )}

            {transferTicketModalOpen && ticket && (
                <TransferTicketModal
                    modalOpen={transferTicketModalOpen}
                    onClose={handleCloseTransferTicketModal}
                    ticketid={ticket?.id}
                    ticketWhatsappId={ticket?.whatsappId}
                    ticketQueueId={ticket?.queueId}
                    ticketStatus={ticket?.status}
                />
            )}
        </div>
    );

};

export default TicketListItemClosed;
